<template>
<div>
  <div>

    <vx-card :title="$i18n.t('Additional')" style="border: 1px solid #ccc;">

      <template slot="actions">
        <vs-button style="margin: 0 auto;" color="primary" size="small" icon-pack="feather" icon="icon-plus" class="mr-4" @click="() => { popup = true }">
          {{ $i18n.t('Add') }}
        </vs-button>
      </template>

      <vs-table :data="additional">

        <template slot="thead">
          <vs-th class="text-center">{{$i18n.t('Reference/Buyer')}}</vs-th>
          <vs-th class="text-right">{{$i18n.t('PONumber')}}</vs-th>
          <vs-th class="text-right">{{$i18n.t('Bast Number')}}</vs-th>
          <vs-th class="text-right">{{$i18n.t('InvoiceNumber')}}</vs-th>
          <vs-th class="text-right">{{$i18n.t('DeliveryDate')}}</vs-th>
          <vs-th class="text-center">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].buyer">
              {{ data[indextr].buyer }}
            </vs-td>

            <vs-td :data="data[indextr].po_number">
              {{ data[indextr].po_number }}
            </vs-td>

            <vs-td :data="data[indextr].bast_number">
              {{ data[indextr].bast_number }}
            </vs-td>

            <vs-td :data="data[indextr].invoice_number">
              {{ data[indextr].invoice_number }}
            </vs-td>

            <vs-td :data="data[indextr].delivery_date">
              {{ data[indextr].delivery_date }}
            </vs-td>

            <vs-td>
              <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="destroy(indextr)" />
            </vs-td>

          </vs-tr>
        </template>

      </vs-table>

      <vs-popup classContent="popup-example" :title="$i18n.t('Spesification')" :active.sync="popup">
        <div class="vx-row mb-6">
          <div class="vx-col md:w-1/2 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Reference/Buyer')" v-model="form.buyer" />
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('PONumber')" v-model="form.po_number" />
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('BAST Number')" v-model="form.bast_number" />
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('InvoiceNumber')" v-model="form.invoice_number" />
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input type="date" class="w-full mb-3" :label="$i18n.t('DeliveryDate')" v-model="form.delivery_date" />
          </div>
        </div>

        <vs-button color="primary" type="filled" @click="add" v-if="!isSelected">Add</vs-button>
        <vs-button color="primary" type="filled" @click="update" v-else>Update</vs-button>
      </vs-popup>

    </vx-card>

  </div>
</div>
</template>

<script>
export default {
  name: "Additional",
  props: ['additional', 'customerID'],
  data: () => ({
    handle: {},
    form: {},
    isSelected: false,
    popup: false,
  }),
  methods: {
    add () {
      this.$emit('add', {
        id: this.customerID,
        form: {
          buyer: this.form.buyer,
          po_number: this.form.po_number,
          bast_number: this.form.bast_number,
          invoice_number: this.form.invoice_number,
          delivery_date: this.form.delivery_date,
        }
      });

      this.popup = false;
    },
    destroy (index) {
      this.$emit('destroy', {
        id: this.customerID,
        index,
      });
    },
  },
  watch: {
    popup() {
      if (!this.popup)
        this.form = {};
    }
  }
}
</script>

<style scoped>

</style>
